import {
  GettersUtility,
  MutationsUtility,
} from '@afrigis/vuex-utilities';

import {
  dataByCoordinate,
} from '@afrigis/aws-weather-storms';

import {
  dataByCoordinate
  as tenDBC,
} from '@afrigis/aws-10-day-weather-forecasts';

const MUTATION_SET_COORDINATES = 'SetCoordinates';
const MUTATION_SET_FORECAST_DATA = 'SetForecastData';
const MUTATION_SET_STORM_DATA = 'SetStormData';
const MUTATION_SET_IS_LOADING = 'SetIsLoading';
const MUTATION_SET_ERROR_MESSAGE = 'SetErrorMessage';
const MUTATION_SET_SELECTED_STORM_GROUP = 'SetSelectedStormGroup';
const MUTATION_SET_SELECTED_FORECAST_GROUP = 'SetSelectedForcastGroup';
const MUTATION_SET_AXIOS_INSTANCE = 'SetAxiosInstance';

const STATEVAR_COORDINATE = 'Coordinate';
const STATEVAR_FORECAST_DATA = 'ForecastData';
const STATEVAR_STORM_DATA = 'StormData';
const STATEVAR_SELECTED_STORM_GROUP = 'SelectedStormGroup';
const STATEVAR_SELECTED_FORECAST_GROUP = 'SelectedForcastGroup';
const STATEVAR_STORM_GROUPS = 'StormGroups';
const STATEVAR_FORECAST_GROUPS = 'ForecastGroups';
const STATEVAR_IS_LOADING = 'IsLoading';
const STATEVAR_ERROR_MESSAGE = 'ErrorMessage';
const STATEVAR_AXIOS_INSTANCE = 'axiosInstance';

const actions = {
  Forecast: async (context) => {
    context.commit(MUTATION_SET_IS_LOADING, true);
    context.commit(MUTATION_SET_ERROR_MESSAGE, null);
    context.commit(MUTATION_SET_FORECAST_DATA, []);

    if (!context.state[STATEVAR_AXIOS_INSTANCE]) {
      throw new Error('No Axios instance set');
    }
    try {
      const payload = {
        axiosInstance: context.state[STATEVAR_AXIOS_INSTANCE],
        latitude: context.state[STATEVAR_COORDINATE].latitude,
        longitude: context.state[STATEVAR_COORDINATE].longitude,
        dayCount: 10,
        stationCount: 20,
        locationBuffer: 100000,
      };
      const { data: { code, result } } = await tenDBC(payload);
      if (code !== 200) {
        throw new Error('error');
      }
      if (result.length === 0) {
        context.commit(MUTATION_SET_FORECAST_DATA, []);
      } else {
        context.commit(MUTATION_SET_FORECAST_DATA, result[0].forecasts);
      }
    } catch (error) {
      const errorDescription = (error.message && error.result[0])
        ? error.response.result[0].message
        : error.response;
      throw new Error(errorDescription);
    } finally {
      context.commit(MUTATION_SET_IS_LOADING, false);
    }
  },
  CurrentStorms: async (context) => {
    context.commit(MUTATION_SET_STORM_DATA, []);
    context.commit(MUTATION_SET_ERROR_MESSAGE, null);

    if (!context.state[STATEVAR_AXIOS_INSTANCE]) {
      throw new Error('No Axios instance set');
    }
    try {
      const payload = {
        axiosInstance: context.state[STATEVAR_AXIOS_INSTANCE],
        includeGeometry: true,
        latitude: context.state[STATEVAR_COORDINATE].stormLat,
        longitude: context.state[STATEVAR_COORDINATE].stormLng,
        locationBuffer: 900000,
        stormGroup: context.state[STATEVAR_SELECTED_STORM_GROUP],
      };
      const { data: { code, result } } = await dataByCoordinate(payload);
      if (code !== 200) {
        throw new Error(result.message);
      }
      context.commit(MUTATION_SET_STORM_DATA, result.features);
    } catch (error) {
      const errorDescription = (error.message && error.result)
        ? error.response.result.message
        : error.response;
      throw new Error(errorDescription);
    } finally {
      context.commit(MUTATION_SET_IS_LOADING, false);
    }
  },
};

const getters = {
  [STATEVAR_AXIOS_INSTANCE]: GettersUtility.getArrayCopy(STATEVAR_AXIOS_INSTANCE),
  [STATEVAR_COORDINATE]: GettersUtility.getArrayCopy(STATEVAR_COORDINATE),
  [STATEVAR_ERROR_MESSAGE]: GettersUtility.get(STATEVAR_ERROR_MESSAGE),
  [STATEVAR_FORECAST_DATA]: GettersUtility.getArrayCopy(STATEVAR_FORECAST_DATA),
  [STATEVAR_FORECAST_GROUPS]: GettersUtility.getArrayCopy(STATEVAR_FORECAST_GROUPS),
  [STATEVAR_IS_LOADING]: GettersUtility.get(STATEVAR_IS_LOADING),
  [STATEVAR_STORM_DATA]: GettersUtility.getArrayCopy(STATEVAR_STORM_DATA),
  [STATEVAR_STORM_GROUPS]: GettersUtility.getArrayCopy(STATEVAR_STORM_GROUPS),
  [STATEVAR_SELECTED_FORECAST_GROUP]: GettersUtility.get(STATEVAR_SELECTED_FORECAST_GROUP),
  [STATEVAR_SELECTED_STORM_GROUP]: GettersUtility.get(STATEVAR_SELECTED_STORM_GROUP),
};

const mutations = {
  [MUTATION_SET_AXIOS_INSTANCE]: MutationsUtility.set(STATEVAR_AXIOS_INSTANCE),
  [MUTATION_SET_COORDINATES]: MutationsUtility.set(STATEVAR_COORDINATE),
  [MUTATION_SET_FORECAST_DATA]: MutationsUtility.set(STATEVAR_FORECAST_DATA),
  [MUTATION_SET_STORM_DATA]: MutationsUtility.set(STATEVAR_STORM_DATA),
  [MUTATION_SET_IS_LOADING]: MutationsUtility.set(STATEVAR_IS_LOADING),
  [MUTATION_SET_ERROR_MESSAGE]: MutationsUtility.set(STATEVAR_ERROR_MESSAGE),
  [MUTATION_SET_SELECTED_STORM_GROUP]: MutationsUtility.set(STATEVAR_SELECTED_STORM_GROUP),
  [MUTATION_SET_SELECTED_FORECAST_GROUP]: MutationsUtility.set(STATEVAR_SELECTED_FORECAST_GROUP),
};

const state = {
  [STATEVAR_AXIOS_INSTANCE]: null,
  [STATEVAR_COORDINATE]: null,
  [STATEVAR_FORECAST_DATA]: [],
  [STATEVAR_STORM_DATA]: [],
  [STATEVAR_STORM_GROUPS]: [
    {
      text: '30 dbz',
      value: '30',
    },
    {
      text: '48 dbz',
      value: '48',
    },
    {
      text: '60 dbz',
      value: '60',
    },
    {
      text: 'Hail',
      value: 'Hail',
    },
    {
      text: 'Lightning',
      value: 'lightning',
    },
    {
      text: 'Radar Lightning',
      value: 'radar_lightning',
    },
  ],
  [STATEVAR_FORECAST_GROUPS]: [
    {
      text: 'Basic',
      value: 'basic',
    },
    {
      text: 'Extended',
      value: 'extended',
    },
    {
      text: 'Astronomical',
      value: 'astronomical',
    },
    {
      text: 'Tides',
      value: 'tides',
    },
  ],
  [STATEVAR_SELECTED_STORM_GROUP]: '30',
  [STATEVAR_SELECTED_FORECAST_GROUP]: ['basic', 'astronomical'],
  [STATEVAR_IS_LOADING]: false,
  [STATEVAR_ERROR_MESSAGE]: null,
};

export default {
  actions,
  getters,
  mutations,
  state,
};
