<template>
  <b-dropdown
    dropleft
    no-caret
    right
    toggle-class="text-decoration-none"
    variant="primary"
  >
    <template #button-content>
       <b-icon-cloud />
    </template>
      <h6 class="mb-2 pl-4 font-weight-bold">Storm movement</h6>
    <b-dropdown-text>
      <span class="legend-icon current"></span>
       Current storm location
    </b-dropdown-text>
    <b-dropdown-text>
      <span class="legend-icon thirty"></span>
      Predicted in 30 minutes
    </b-dropdown-text>
    <b-dropdown-text>
      <span class="legend-icon sixty"></span>
      Predicted in 60 minutes
    </b-dropdown-text>
    <!--<b-form-group
      class="px-4 mt-3"
      v-slot="{ ariaDescribedby }"
    >
      <h6 class="mb-2 font-weight-bold">Storm Groups</h6>
      <b-form-radio-group
        :aria-describedby="ariaDescribedby"
        :options="StormGroups"
        plain
        stacked
        v-model="selected"
      />
    </b-form-group>-->
  </b-dropdown>
</template>

<script>
import {
  mapGetters,
  mapMutations,
} from 'vuex';

import {
  BDropdown,
  BDropdownText,
  BIconCloud,
} from 'bootstrap-vue';

export default {
  components: {
    BDropdown,
    BDropdownText,
    BIconCloud,
  },
  computed: {
    ...mapGetters({
      StormGroups: 'weatherStore/StormGroups',
      SelectedStormGroup: 'weatherStore/SelectedStormGroup',
    }),
    selected: {
      get() {
        return this.SelectedStormGroup;
      },
      set(selected) {
        const group = this.StormGroups.find((type) => selected === type.value).value;
        this.SetSelectedStormGroup(group);
      },
    },
  },
  methods: {
    ...mapMutations({
      SetSelectedStormGroup: 'weatherStore/SetSelectedStormGroup',
    }),
  },
  name: 'StormsLegend',
};
</script>

<style>

.legend-icon {
  display: inline-block;
  width: 14px;
  height: 14px;
  vertical-align: middle;
  background-color: #02A0B9;
  border: 1px dashed #02A0B9;
}

.legend-icon.thirty {
  opacity: .35;
}
.legend-icon.sixty {
  opacity: .2;
}
</style>
