<template>
  <b-table borderless
    :busy="IsLoading"
    :items="forecastData"
    :fields="headerNames"
    small
    stacked="sm"
    empty-text="No data available"
    :show-empty="showNoDataWarning"
    >
    <template #table-busy>
      <div class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Loading...</strong>
      </div>
    </template>
      <template #head(temp)>
        <span>&#8451;</span>
      </template>
    <template #cell(date)="row">
      {{row.item.name}}, {{row.item.date}}
    </template>
    <template #cell(forecast)="row">
      <img
        alt="forecastIcon"
        class="icon"
        :src="getImage(row.item)"
      />{{getDescription(row.item)}}
    </template>
    <template #cell(temp)="row">
      <span style="white-space: nowrap">
        {{getTemp(row.item)}}
      </span>
    </template>
    <template #cell(wind)="row">
        <p class="text-xs-left text-sm-center">{{getWind(row.item)}}</p>
    </template>
  </b-table>
</template>

<script>
import { mapGetters } from 'vuex';

import {
  BSpinner,
  BTable,
} from 'bootstrap-vue';

export default {
  components: {
    BSpinner,
    BTable,
  },
  computed: {
    ...mapGetters({
      IsLoading: 'weatherStore/IsLoading',
      ForcastsData: 'weatherStore/ForecastData',
    }),
    showNoDataWarning() {
      return !this.IsLoading || !this.ForcastsData.length === 0;
    },
    forecastData() {
      if (!this.ForcastsData) {
        return [];
      }
      return this.ForcastsData;
    },
    headerNames() {
      return [
        {
          key: 'date',
          label: 'Day',
          sortable: false,
        },
        {
          key: 'forecast',
          label: 'Forecast',
          sortable: false,
        },
        {
          key: 'temp',
          label: 'Temperature',
          sortable: false,
        },
        {
          key: 'wind',
          label: 'Wind Speed(km/h) | Direction',
          sortable: false,
        },
      ];
    },
  },
  methods: {
    getReading(data) {
      if (!data.readings) {
        return null;
      }
      return data.readings.find((obj) => obj.time === '08:00') || data.readings[0];
    },
    getWind(data) {
      const reading = this.getReading(data);
      if (!reading) {
        return '- | -';
      }
      return `${reading.wind_speed_kph} | ${reading.wind_direction_short}`;
    },
    getTemp(data) {
      if (!data.daily || data.daily.temperature_minimum == null
        || data.daily.temperature_maximum == null) {
        return '- | -';
      }
      return `${data.daily.temperature_minimum} | ${data.daily.temperature_maximum}`;
    },
    getDescription(data) {
      const reading = this.getReading(data);
      if (!reading) {
        return null;
      }
      return reading.description;
    },
    getImage(data) {
      const description = this.getDescription(data);
      if (!description) {
        return null;
      }
      return this.getImageAsset(description);
    },
    getImageAsset(data) {
      switch (data.toLowerCase()) {
        case 'clear skies':
          return require('@/assets/icons/clearskies.png');
        case 'cloud':
          return require('@/assets/icons/Cloud.png');
        case 'cloud rain':
          return require('@/assets/icons/CloudRain.png');
        case 'cloud rain thunder':
          return require('@/assets/icons/CloudRainThun.png');
        case 'cloudy':
          return require('@/assets/icons/Cloudy.png');
        case 'drizzle':
          return require('@/assets/icons/Drizzle.png');
        case 'fog':
          return require('@/assets/icons/Fog.png');
        case 'frost':
          return require('@/assets/icons/Frost.png');
        case 'haze':
          return require('@/assets/icons/Haze.png');
        case 'heavy rain':
          return require('@/assets/icons/Heavyrain.png');
        case 'heavy rain shower':
          return require('@/assets/icons/Heavyrainshower.png');
        case 'heavy snow':
          return require('@/assets/icons/Heavysnow.png');
        case 'high level clouds':
          return require('@/assets/icons/highlevelclouds.png');
        case 'mist':
          return require('@/assets/icons/Mist.png');
        case 'moderate rain':
          return require('@/assets/icons/Moderaterain.png');
        case 'mostly clear':
          return require('@/assets/icons/mostlyclear.png');
        case 'overcast':
          return require('@/assets/icons/overcast.png');
        case 'partly cloudy':
          return require('@/assets/icons/partlycloudy.png');
        case 'rain showers':
          return require('@/assets/icons/Rainshowers.png');
        case 'severe thunderstorms':
          return require('@/assets/icons/Severethunderstorms.png');
        case 'showers and thundershowers':
          return require('@/assets/icons/ShowersandThundershowers.png');
        case 'slight or moderate snow':
          return require('@/assets/icons/Slightormoderatesnow.png');
        case 'slight rain':
          return require('@/assets/icons/Slightrain.png');
        case 'snow':
          return require('@/assets/icons/Snow.png');
        case 'snow on the mountains':
          return require('@/assets/icons/Snowonthemountains.png');
        case 'sun':
          return require('@/assets/icons/Sun.png');
        case 'sun cloud':
          return require('@/assets/icons/SunCloud.png');
        case 'sun cloud rain':
          return require('@/assets/icons/SunCloudRain.png');
        case 'thunder storms':
          return require('@/assets/icons/Thunderstorms.png');
        case 'wind':
          return require('@/assets/icons/wind.png');
        default:
          return require('@/assets/icons/clearskies.png');
      }
    },
  },

};
</script>
