<template>
  <div>
    <b-card
      id="trust-us-card"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
      text-variant="white"
    >
      <div class="content">
        <h5>Trust us for</h5>
        <ul>
          <li>SABS and ISO compliant Address data.</li>
          <li>Accurate and verified address results returned.</li>
          <li>
            Components available via API to integrate into your own solutions.
          </li>
          <li>Over 50 Million searchable address combinations.</li>
        </ul>
      </div>
    </b-card>
    <b-card class="remove-border-card mb-3">
      <h4>Possible uses:</h4>
      <b-card-text>
        <ul>
          <li>View current weather</li>
          <li>Plan your week with a 10-day weather forecast</li>
          <li>Keep track of severe weather activity</li>
          <li>Receive severe weather warnings pertinent to you</li>
          <li>Pull reports on historic weather occurrences</li>
          <li>
            Predict where lightning strikes usually cause damage and insure
            accordingly
          </li>
        </ul>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
} from 'bootstrap-vue';

const backgroundImage = require('@/assets/TTB_Image_1920x300.webp');

export default {
  components: {
    BCard,
    BCardText,
  },
  computed: {
    backgroundImage: () => backgroundImage,
  },
  name: 'InformationSection',
};
</script>

<style>
#trust-us-card {
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 250px;
  border-bottom:0;
  border-color: white;
}
#trust-us-card .content{
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
}

.remove-border-card {
  border-top: 0;
  border-color: white;
}
</style>
