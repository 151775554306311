<template>
  <b-container fluid>
    <b-row>
      <b-col md="4" class="p-3 text-left">
        <h1>Weather Insights</h1>
      </b-col>
      <b-col md="4" class="ml-auto p-3 text-right appCtrlButtons">
        <b-button
          @click="shareViaWebShare"
          title="Share This App"
          variant="outline-secondary"
        >
          <b-icon-share />
        </b-button>
        <b-modal
          id="modal-1"
          title="Share This App"
          ref="fallback-modal"
          @ok="handleOk"
        >
          <b-form-textarea
            id="share-fallback"
            ref="toCopy"
            v-model="shareUrl"
            rows="1"
            max-rows="6"
          ></b-form-textarea>
          <template #modal-footer="{ ok }">
            <b-button size="sm" variant="primary" @click="ok()">
              Copy URL to Clipboard
            </b-button>
          </template>
        </b-modal>
        <b-button @click="startTour" variant="outline-secondary">
          Start Tour
        </b-button>
      </b-col>
    </b-row>
    <contact-expert />
    <v-tour
      class="waether-tour"
      name="weatherTour"
      :options="tourOptions"
      :steps="weatherTourSteps"
    />
    <b-row>
      <!--Column 1 -->
      <b-col class="greyed-out mb-3" lg="8">
        <b-row align-h="center">
          <b-col cols="8" class="mb-2 mt-2">
            <form autocomplete="off" @submit.prevent="submitSearch">
              <ag-autocomplete
                autofocus
                id="autocomplete"
                class="autocomplete-component"
                :options="suggestions"
                placeholder="Address"
                :processing="loadingSuggestions"
                :text="searchText"
                @cleared="clearAddress"
                @onChosen="chosen"
                @onFocus="fetchAwsCredentials"
                @onInput="startAutocomplete"
                @onSearch="doSearch"
              />
            </form>
          </b-col>
        </b-row>
        <b-alert class="mt-5 text-center" show v-if="searchError" variant="danger">
          {{ searchError }}
        </b-alert>
        <b-row class="mt-5">
          <!--Column 1 -->
          <b-col class="mb-2" lg="6">
            <b-card
              id="weather-forecast"
              header-tag="header"
            >
              <template #header>
                <h4>10 Day weather forecast in your area</h4>
              </template>
              <forecast />
            </b-card>
          </b-col>
          <!--Column 2 -->
          <b-col class="mb-2" lg="6">
            <b-card
              id="weather-map"
              header-tag="header"
              no-body
            >
              <template #header>
                <h4>Current storms in your area</h4>
                <h5 class="text-secondary">Note: View of storms only
                    available if it is currently raining.</h5>
              </template>
              <storms-legend class="map-legend" />
              <leaflet-map />
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <!--Column 2 -->
      <b-col class="mb-2" lg="4">
        <infomation-section />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapMutations,
} from 'vuex';

import addressSearch from '@/mixins/addressSearch';

import AgAutocomplete from '@afrigis/afrigis-vue-autocomplete';
import ContactExpert from '@/components/ContactExpert.vue';
import Forecast from '@/components/Forecast.vue';
import LeafletMap from '@/components/LeafletMap.vue';
import InfomationSection from '@/components/InfomationSection.vue';
import {
  BAlert,
  BButton,
  BCard,
  BCol,
  BContainer,
  BFormTextarea,
  BIconShare,
  BRow,
} from 'bootstrap-vue';
import StormsLegend from '@/components/StormsLegend.vue';

export default {
  components: {
    AgAutocomplete,
    BAlert,
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormTextarea,
    BIconShare,
    BRow,
    ContactExpert,
    Forecast,
    LeafletMap,
    InfomationSection,
    StormsLegend,
  },
  computed: {
    ...mapGetters({
      AwsAxiosInstance: 'awsAxiosStore/AwsGetAuth',
      DetailedResults: 'addressSearchStore/detailedResults',
      SelectedStormGroup: 'weatherStore/SelectedStormGroup',
      SelectedForecastGroup: 'weatherStore/SelectedForecastGroup',
      Suggestions: 'addressSearchStore/suggestions',
    }),
    shareUrl() {
      const { origin, pathname } = window.location;
      return `${origin}${pathname}`;
    },
    tourOptions() {
      return {
        highlight: true,
        labels: {
          buttonSkip: 'Skip tour',
          buttonPrevious: 'Previous',
          buttonNext: 'Next',
          buttonStop: 'Thanks',
        },
        useKeyboardNavigation: false,
      };
    },
    weatherTourSteps() {
      return [
        {
          content:
            'Type an address and see the weather forecast as well as current storms in your area.',
          header: {
            title: 'Address Search',
          },
          params: {
            placement: 'auto',
          },
          target: '#autocomplete',
        },
        {
          content: 'See the weather forecast in your area for the next ten days.',
          header: {
            title: 'Ten day weather forecast',
          },
          params: {
            placement: 'auto',
          },
          target: '#weather-forecast',
        },
        {
          content: 'View current storms on a map. The map updates every 5 minutes.',
          header: {
            title: 'View current storms on a map',
          },
          params: {
            placement: 'auto',
          },
          target: '.map-container',
        },
        {
          content: 'Inspect the legend to see the current and predicted timeframes.',
          header: {
            title: 'Map legend',
          },
          params: {
            placement: 'auto',
          },
          target: '.map-legend',
        },
      ];
    },
  },
  data() {
    return {
      intervalFunction: null,
    };
  },
  destroyed() {
    window.removeEventListener('iris:share-capability', this.shareViaWebShare);
    window.removeEventListener('iris:start-tour', this.shareViaWebShare);
  },
  methods: {
    ...mapActions({
      GetForecast: 'weatherStore/Forecast',
      GetCurrentStorms: 'weatherStore/CurrentStorms',
      LoadAwsAuthentication: 'awsAxiosStore/AwsLoadAuth',
    }),
    ...mapMutations({
      SetDetailedResult: 'addressSearchStore/commitDetailedResults',
      SetStormCoordinate: 'weatherStore/SetCoordinates',
      SetWetherAxiosInstance: 'weatherStore/SetAxiosInstance',
    }),
    chosen(data) {
      this.clearErrorMessage();
      this.onChosen(data);
    },
    isTourOn() {
      const { tour } = this.$route.query;
      if (!tour) {
        return false;
      }
      return /^true$/i.test(tour.toLowerCase());
    },
    handleOk() {
      const Url = this.$refs.toCopy;
      Url.innerHTML = this.shareUrl;
      Url.select();
      document.execCommand('copy');
      this.$bvModal.msgBoxOk('URL Copied to Clipboard successfully', {
        title: 'Confirmation',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true,
      });
    },
    loadWeather() {
      this.GetForecast();
      this.GetCurrentStorms();
      if (this.intervalFunction) {
        clearInterval(this.intervalFunction);
      }
      this.intervalFunction = setInterval(() => {
        this.GetForecast();
        this.GetCurrentStorms();
      }, 1000 * 60 * 3);
    },
    startAutocomplete(data) {
      this.clearErrorMessage();
      this.autocomplete(data);
    },
    shareViaWebShare() {
      if (navigator.share) {
        navigator
          .share({
            title: document.title,
            url: this.shareUrl,
          })
          .then()
          .catch(() => {
            // Handle the error!
          });
      } else {
        this.$refs['fallback-modal'].show();
      }
    },
    startTour() {
      this.$tours.weatherTour.start();
    },
    tourConfigure() {
      const hasSeenTour = JSON.parse(
        localStorage.getItem('hasSeenWeatherTour'),
      );
      if (!hasSeenTour || this.isTourOn()) {
        this.startTour();
        localStorage.setItem('hasSeenWeatherTour', true);
      }
    },
  },
  mixins: [addressSearch],
  async mounted() {
    await this.LoadAwsAuthentication();
    this.SetWetherAxiosInstance(this.AwsAxiosInstance);
    window.addEventListener('iris:share-capability', this.shareViaWebShare);
    window.addEventListener('iris:start-tour', this.startTour);
    const latitude = -25.808611;
    const longitude = 28.256111;
    const stormLat = -28.4792625;
    const stormLng = 24.6727135;
    const data = {
      centroid: {
        latitude,
        longitude,
        stormLat,
        stormLng,
      },
    };
    this.SetDetailedResult(data);
    this.SetStormCoordinate({
      latitude,
      longitude,
      stormLat,
      stormLng,
    });
    this.loadWeather();
    this.tourConfigure();
  },
  name: 'Weather',
  watch: {
    DetailedResults(newV) {
      if (!newV || !newV.location) {
        return;
      }
      const { location: { lat, lng } } = newV;
      this.SetStormCoordinate({
        latitude: lat,
        longitude: lng,
        stormLat: -28.4792625,
        stormLng: 24.6727135,
      });
      this.loadWeather();
    },
    SelectedForecastGroup(newV) {
      if (!newV) {
        return;
      }
      this.GetForecast(newV);
    },
    SelectedStormGroup(newV) {
      if (!newV) {
        return;
      }
      this.GetCurrentStorms(newV);
    },
    Suggestions(newV) {
      if (!newV) {
        return;
      }
      this.clearErrorMessage();
    },
  },
};
</script>
<style src="vue-tour/dist/vue-tour.css"></style>
<style scoped>
#share-fallback{
  pointer-events: none;
  background-color: lightgrey;
}

.autocomplete-component {
  position: absolute;
  width: 100%;
  z-index: 900;
}
.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4);
}

.waether-tour {
  z-index: 500;
}
.map-legend {
  position: absolute;
  top: 120px;
  right: 19px;
  z-index: 500;
  cursor: pointer;
}
.greyed-out {
  background-color: #D1D2D4;
  min-height: 100%;
}
@media screen and (max-width: 400px) {
  .appCtrlButtons {
    display: none
  }
}
</style>
