<template>
  <div class="map-container" :id="leafletContainerId">Loading Map...</div>
</template>

<script>
import {
  mapGetters,
} from 'vuex';

import 'leaflet/dist/leaflet.css';
import L from 'leaflet/dist/leaflet';

const markerIconUrl = require('@/assets/icons/origin.png');

export default {
  computed: {
    ...mapGetters({
      DetailedResults: 'addressSearchStore/detailedResults',
      CurrentStormData: 'weatherStore/StormData',
    }),
    center: () => [-28.4792625, 24.6727135],
    countryBoundingBox: () => [
      [-34.8191663551, 16.3449768409],
      [-22.0913127581, 32.830120477],
    ],
    leafletContainerId: () => 'leaflet-container',
    selectedBaseType: () => 'vector',
  },
  data() {
    return {
      agMapLoaded: false,
      leafletMap: null,
      marker: null,
      stormLayers: [],
    };
  },
  methods: {
    checkIfAgMapLoaded() {
      this.agMapLoaded = !!window.AfriGIS;
      if (this.agMapLoaded) {
        return;
      }
      setTimeout(() => {
        this.checkIfAgMapLoaded();
      }, 500);
    },
    firstLandingMarker([latitude, longitude]) {
      if (this.marker) {
        this.leafletMap.removeLayer(this.marker);
      }
      this.marker = L.marker(([latitude, longitude]), {
        icon: this.getIcon(),
        draggable: false,
      }).addTo(this.leafletMap);
      const latLngBounds = new L.LatLngBounds();
      latLngBounds.extend(this.marker.getLatLng());
      this.leafletMap.fitBounds(latLngBounds);
    },
    clearStormLayers() {
      this.stormLayers.forEach((m) => {
        this.leafletMap.removeLayer(m);
      });
      this.stormLayers = [];
    },
    drawMarker(latitude, longitude) {
      if (this.marker) {
        this.leafletMap.removeLayer(this.marker);
      }
      this.marker = L.marker(L.latLng([latitude, longitude]), {
        icon: this.getIcon(),
        draggable: false,
      }).addTo(this.leafletMap);
      const latLngBounds = new L.LatLngBounds();
      latLngBounds.extend(this.marker.getLatLng());
      this.leafletMap.fitBounds(latLngBounds);
    },
    drawStormWeather(data) {
      data.forEach((i) => {
        const stormLayer = L.geoJSON(i.geometry, {
          style: this.getStyle(i.properties.instance),
        }).addTo(this.leafletMap);
        this.stormLayers.push(stormLayer);
      });
    },
    getStyle(item) {
      switch (item) {
        case 0:
          return {
            fillColor: '#2F8FA8',
            weight: 1,
            opacity: 1,
            color: '#2F8FA8',
            fillOpacity: 0.5,
          };
        case 1:
          return {
            fillColor: '#2F8FA8',
            weight: 1,
            opacity: 1,
            dashArray: '3',
            color: '#02A0B9',
            fillOpacity: 0.35,
          };
        case 2:
          return {
            fillColor: '#2F8FA8',
            weight: 1,
            opacity: 1,
            dashArray: '3',
            color: '#02A0B9',
            fillOpacity: 0.2,
          };
        default:
          return {
            fillColor: '#2F8FA8',
            weight: 1,
            opacity: 1,
            color: '#2F8FA8',
            fillOpacity: 0.5,
          };
      }
    },
    getIcon() {
      return L.icon({
        iconAnchor: new L.Point(16, 32),
        iconSize: new L.Point(32, 32),
        iconUrl: markerIconUrl,
      });
    },
    initMap() {
      if (this.leafletMap) {
        return;
      }

      this.leafletMap = window.AfriGIS.map(this.leafletContainerId, {
        center: L.latLng(this.center),
        authkey: process.env.VUE_APP_AG_LEAFLET_API_KEY,
        mapType: this.selectedBaseType,
        maxBounds: L.latLngBounds(L.latLng(-90, -180), L.latLng(90, 180)),
        maxBoundViscosity: 0,
        maxZoom: 18,
        minZoom: 3,
        zoom: 5,
        zoomControl: true,
      });
      this.leafletMap.fitBounds(this.countryBoundingBox);
      this.leafletMap.zoomControl.setPosition('bottomright');
      this.firstLandingMarker(this.center);
    },
  },
  mounted() {
    this.checkIfAgMapLoaded();
  },
  name: 'LeafletMap',
  watch: {
    agMapLoaded(newV) {
      if (!newV) {
        return;
      }
      this.initMap();
    },
    DetailedResults(newV) {
      if (!newV || !newV.location) {
        return;
      }
      const { location: { lat, lng } } = newV;
      this.drawMarker(lat, lng);
    },
    CurrentStormData(newV) {
      if (!newV) {
        return;
      }
      this.clearStormLayers();
      this.drawStormWeather(newV);
    },
  },
};
</script>

<style>
.leaflet-bar a {
  background-color: var(--primary) !important;
  color: #fff !important;
}
.map-container {
  position: relative;
  width: 100%;
  height: 50vh;
}
.leaflet-interactive {
  cursor: grab;
}
.leaflet-marker-icon {
  cursor: pointer;
}
</style>
