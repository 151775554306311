export default {
  ACTIONS: {
    LOAD: 'Load',
  },

  GETTERS: {
    DATA: 'Data',
    IS_LOADING: 'IsLoading',
  },

  MUTATIONS: {
    SET_DATA: 'SetData',
    SET_IS_LOADING: 'SetIsLoading',
  },
  AwsAxiosStore: {
    Mutations: {
      SetToken: 'SetToken',
      SetKey: 'SetKey',
      SetTokenRefreshStrategy: 'SetTokenRefreshStrategy',
    },
    Name: 'awsAxiosStore',
    StateVars: {
      Instance: 'instance',
      Key: 'key',
      Token: 'token',
      TokenRefreshStrategy: 'tokenRefreshStrategy',
    },
  },
  STATE_VARS: {
    DATA: 'data',
    IS_LOADING: 'isLoading',
  },
};
