import axios from 'axios';

import { GettersUtility, ModuleUtilities, MutationsUtility } from '@afrigis/vuex-utilities';

import { awsAxiosInstance } from '@afrigis/aws-axios-instance';

import Constants from '@/store/Constants';

const {
  ACTIONS,
  MUTATIONS,
  STATE_VARS,
  AwsAxiosStore: AAS,
} = Constants;

const ENDPOINT = `${process.env.VUE_APP_ROOT_API}/proxies/search/api`;

const initialState = () => ({
  [STATE_VARS.IS_LOADING]: false,
  [AAS.StateVars.Key]: null,
  [AAS.StateVars.Token]: null,
  [AAS.StateVars.TokenRefreshStrategy]: null,
});

const STATE_PROPS_TO_EXPOSE = [
  ...Object.values(AAS.StateVars),
  STATE_VARS.IS_LOADING,
];
const actions = {
  AwsLoadAuth: async (context) => {
    context.commit(MUTATIONS.SET_IS_LOADING, true);
    try {
      const { data: { code, message, result } } = await axios.get(`${ENDPOINT}/AwsAuthentication/`);
      if (code !== 200) {
        throw new Error(message);
      }
      const { key, token } = result;
      context.commit(AAS.Mutations.SetKey, key);
      context.commit(AAS.Mutations.SetToken, token);

      if (context.state[AAS.StateVars.TokenRefreshStrategy]) {
        clearTimeout(context.state[AAS.StateVars.TokenRefreshStrategy]);
      }

      const refreshTimer = 3600 * 1000 - 60000;
      const refresh = setInterval(() => {
        context.dispatch(ACTIONS.AwsLoadAuth);
      }, refreshTimer);
      context.commit(AAS.Mutations.SetTokenRefreshStrategy, refresh);
    } catch (err) {
      throw new Error(`Could not load authentication token - ${err}`);
    } finally {
      context.commit(MUTATIONS.SET_IS_LOADING, false);
    }
  },
};

const state = initialState();

const getters = {
  ...GettersUtility.scaffold(state, [STATE_VARS.IS_LOADING]),
  AwsGetAuth: (stateP) => {
    const axiosInstance = awsAxiosInstance(stateP[AAS.StateVars.Token], stateP[AAS.StateVars.Key]);
    return axiosInstance;
  },
};

const mutations = {
  ...MutationsUtility.scaffold(state, STATE_PROPS_TO_EXPOSE),
  [MUTATIONS.RESET]: ModuleUtilities.ResetToInitial(initialState),
};

export default {
  actions,
  getters,
  mutations,
  state,
};
